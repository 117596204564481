@import url('../src/styles/normalize.css');
@import url('../src/styles/webflow.css');
@import url('../src/styles/fonts.css');
@import url('../src/styles/restia.webflow.css');

html,
body {
  font-family: Montserrat, sans-serif !important;
  max-width: 100vw;
  overflow-x: hidden;
}

input {
  accent-color: #751a4b;
}
.calculator {
  &-content {
    display: none !important;
    &.is-active {
      display: flex !important;
    }
  }
  &-navigation {
    cursor: auto;
    &:hover {
      cursor: pointer;
    }
  }
}

.nav-link,
.list-item {
  outline: none;
  &:active,
  &:focus {
    color: white !important;
    outline: none !important;
    border: none !important;
  }
}

.toggle-button {
  background-color: #f0f0f0 !important;
  cursor: pointer;
  &.is-active {
    background-color: #45db8a !important;
    .button-toggle {
      transform: translate3d(13px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg) !important;
    }
  }
  &,
  * {
    transition: 0.2s ease-in-out all;
  }
  .button-toggle {
    transform: translateX(-1px) !important;
  }
}

.summary-form-item-value {
  &-edit-button {
    cursor: pointer;
  }
  img:not(.summary-form-item-value-edit-button) {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 992px) {
  .calculator-content-list .calculator-content-list-item-mobile {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .calculator-content-list .calculator-content-list-item-mobile {
    display: block !important;
  }
  .nav-link {
    &:active {
      color: #751a4b !important;
    }
  }
  .nav-link.w--current {
    -webkit-text-stroke: 1px #751a4b !important;
  }
}

.nav-link.w--current {
  -webkit-text-stroke: 1px white;
}

.button {
  color: white !important;
  &:focus {
    outline: none;
    border: none;
  }
  &-simple {
    color: #66646e !important;
    font-weight: 300 !important;
    &-big {
      &-selected {
        color: #b22427 !important;
        font-weight: 600 !important;
      }
    }
    &-small {
      &-selected {
        color: #66646e !important;
        font-weight: 600 !important;
      }
    }
  }
  &-outlined-small {
    color: #66646e !important;
    &:hover {
      color: white !important;
    }
  }
}

.extra-grid-item {
  padding: 20px 30px !important;
  &:nth-of-type(4n + 1),
  &:nth-of-type(4n + 2) {
    background-color: #f7f7f7;
  }
}

.w-slider-dot {
  height: 16px;
  border-radius: 200px !important;
  border: 4px solid white !important;
  width: 60px;
  background-color: #b9b9b9 !important;
  margin: 0;
  &.w-active {
    background-color: #b22427 !important;
  }
}

.tablet-image {
  cursor: pointer;
}

.faq-question .faq-question-right-value div {
  display: inline-block;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 476px) {
  .faq-question .faq-question-right-value div {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 992px) {
  .af-view .w-nav {
    position: static;
    &-overlay {
      .w-nav-menu {
        width: 90%;
        left: 5%;
        z-index: 2;
        color: black;
        a {
          color: #751a4b;
          &:hover {
            color: #751a4b;
            border-bottom: 1px solid #751a4b;
          }
        }
        .button-login {
          background-color: #b22427;
          &:hover {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.is-hidden--text {
  visibility: hidden;
  color: transparent;
}

.faq-accordion {
  .faq-question {
    &-arrow {
      transform: none !important;
      &-wrapper {
        transform: none !important;
        transition: 0.2s linear all !important;
      }
    }
  }
  .faq-answer {
    &-wrapper {
      height: auto !important;
      max-height: 0 !important;
      overflow: hidden !important;
    }
  }
  &.open {
    .faq-question-arrow {
      &-wrapper {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(-90deg) skew(0deg) !important;
        transform-style: preserve-3d !important;
      }
    }
    .faq-answer-wrapper {
      max-height: 1000px !important;
    }
  }
}

.form__message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  &--success {
    background-color: #dddddd;
  }
  &--fail {
    background-color: #ffdede;
  }
}

.wf-section,
.pricing-tag-bottom,
.toggle-button {
  outline: none !important;
}

.footer-link-big {
  &:focus,
  &:active {
    outline: none !important;
  }
}

//Lightbox
.lightbox {
  position: fixed;
  top: 50px !important;
}

.w-video {
  border: 10px solid #000;
  border-radius: 20px;
}

.tablet {
  position: absolute;
  &-left {
    left: -72px;
  }
  &-right {
    right: -74px;
  }
}

@media screen and (max-width: 768px) {
  .tablet {
    height: 240px;
    top: 50px;
    &-left {
      top: 50px;
      left: -42px;
    }
    &-right {
      right: -43px;
    }
  }
}

.error-page-body {
  display: flex;
  justify-content: center;
  align-items: center;

  .error-img {
    max-width: 100%;
    height: auto;
    max-height: 100vh;
  }
}

.anchor-style-removed {
  text-decoration: none;
  color: #fff;
}

.language-dropdown {
  span {
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 900;
    // color: #ffffff;
  }
}
.dropdown-content {
  padding-top: 10px;
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  & > a:first-child {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  & > a:nth-child(2) {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}
/* Links inside the dropdown */
.dropdown-content a {
  background-color: #f9f9f9;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  span {
    color: black !important;
  }
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.language-dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.language-dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
